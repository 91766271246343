import React from "react"
import { Profiles, Videos } from "../../data/assets"
import { Routes } from "../../data/routes"
import CTA from "../../components/CTA"
import FeaturesThreeColumn from "../../components/FeaturesThreeColumn"
import Img from "../../components/img"
import PageHeader from "../../components/PageHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Our Online Math Tutors for Kids and Highschool Students",
  subheader: "",
  header: `The best of the best math tutors, there when students need them`,
  description: `Yup tutors are thoroughly screened for math expertise and teaching
    skills as part of a rigorous application process. Only the top 5% of applicants
    are accepted. They are current and former teachers, education professionals,
    and graduate students with extensive math experience. This process ensures that Yup
    students receive the highest quality math help from committed tutors.`,
  video: Videos.expertTutors,

  // Quality assurance section
  qaHeader: "How Yup guarantees high-quality tutoring",
  qaSubheader: "",
  qaMeasures: [
    {
      icon: "icon-socratic-method.svg",
      name: "Session review",
      description: `Sessions are hand-graded by a Tutor Quality Manager to ensure
        adherence to Yup's pedagogy, or teaching methods`,
    },
    {
      icon: "icon-enrichment.svg",
      name: "Tutor enrichment",
      description: `Yup provides all tutors with enrichment resources, like courses
        and feedback, to help them improve their skills`,
    },
    {
      icon: "icon-pencil.svg",
      name: "Student engagement",
      description: `After each session, tutors are required to provide information
        on student engagement and tips for future sessions. This information is then
        made available to educators or parents!`,
    },
  ],

  // Testimonials section
  testimonials: [
    {
      testimonial: `I love tutoring for Yup because I can teach students far beyond the
        limits of time and location. When a student gains the confidence and skills to
        succeed in math, it opens up many new opportunities. I feel grateful to play a
        small role in my students' successes.`,
      profile: Profiles.kathleen,
      name: "Kathleen K.",
    },
    {
      testimonial: `I love tutoring math because I am passionate about the importance of
        fearless and persistent problem-solving.  It is an empowering approach to math
        and also to life!  I strive to develop this in students by creating a positive
        and patient dialogue where I ask questions that allow them to discover concepts
        on their own. In the end, there is nothing more exciting than a student reaching
        their 'AHA!' moment!`,
      profile: Profiles.christine,
      name: "Christine Q.",
    },
    {
      testimonial: `I can remember being a student when suddenly the concept being taught
        would just click in my head and I understood. I loved those breakthrough moments,
        and by tutoring with Yup, I now have the opportunity to help today's students
        experience the same thing.`,
      profile: Profiles.samuel,
      name: "Samuel M.",
    },
    {
      testimonial: `I've been with Yup for many years now and I thoroughly enjoy working
        here. Yup brings people who share a passion for making a difference in education
        together, and the team diligently provides nothing but the best to Yup's users.
        Yup's goal of making education more accessible is sure to bring about a change
        in learning—and I'm excited to be a part of it`,
      profile: Profiles.madhu,
      name: "Madhu R.",
    },
  ],

  // CTA section
  cta: "Get to know more about how we teach and why parents love us",
  ctaActions: [
    {
      link: Routes.learningFundamentals,
      text: "Teaching methods",
    },
    {
      link: Routes.reviews,
      text: "Parent reviews",
    },
  ],
}

type TestimonialProps = {
  image: string
  testimonial: string
  name: string
}

function Testimonial(props: TestimonialProps) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/testimonials#component-c5694e9aacc7edd691e3234d067adc62
  return (
    <div className="pt-16 lg:py-16 ">
      <div className="pb-16 bg-secondary-50 lg:pb-0 lg:z-10 lg:relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="relative lg:-my-8">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-1/2 bg-background lg:hidden"
            />
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
              <div className="mx-auto max-w-sm aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <Img
                  className="lg:h-full lg:w-full"
                  src={props.image}
                  alt="Math tutor smiling"
                  cover={true}
                />
              </div>
            </div>
          </div>
          <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
              <blockquote>
                <div>
                  <svg
                    className="h-12 w-12 text-secondary-200"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="mt-6 text-2xl font-medium text-secondary-700">
                    {props.testimonial}
                  </p>
                </div>
                <footer className="mt-6">
                  <p className="text-base font-medium text-secondary-400 font-sans">
                    {props.name}
                  </p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const OurTutors = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.header}
        route={Routes.ourTutors}
      />
      <PageHeader
        smallTitle=""
        mainTitle={Content.header}
        description={Content.description}
        video={Content.video}
      />
      <FeaturesThreeColumn
        title={Content.qaHeader}
        subtitle={Content.qaSubheader}
        features={Content.qaMeasures}
      />
      {Content.testimonials.map(testimonial => (
        <Testimonial
          key={testimonial.name}
          image={testimonial.profile}
          testimonial={testimonial.testimonial}
          name={testimonial.name}
        />
      ))}
      <CTA title={Content.cta} actions={Content.ctaActions} />
    </Layout>
  )
}

export default OurTutors
