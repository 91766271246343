import React from "react"
import Card from "./card"
import Img from "./img"

type Feature = {
  name: string
  icon: string
  description: string
}

type FeaturesThreeColumnProps = {
  title: string
  subtitle?: string
  features: Array<Feature>
}

export default function FeaturesThreeColumn(props: FeaturesThreeColumnProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-c683653471044e6ffc32739e199dfbf2
  return (
    <Card>
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <p className="text-4xl font-extrabold text-center my-12 sm:m-8">
          {props.title}
        </p>
        {props.subtitle && (
          <p className="text-xl text-center mb-16 text-gray-500">
            {props.subtitle}
          </p>
        )}
        <dl className="text-center lg:text-left space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8 mt-8">
          {props.features.map(feature => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-16 w-16 mx-auto lg:mx-0">
                  <Img
                    className="h-16 w-16"
                    src={feature.icon}
                    alt={feature.name}
                  />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Card>
  )
}
